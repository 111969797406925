import { TeamRelayAdminPanel } from '../components/Game/Blocks/TeamRelay';

function TeamRelaySettingsRoute() {
  return (
    <div className='w-full h-full flex flex-col text-white px-10 pb-10'>
      <TeamRelayAdminPanel />
    </div>
  );
}

export const Component = TeamRelaySettingsRoute;

export function clientLoader() {
  return null;
}
